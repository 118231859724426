
.rdt_TableHeader{
    border-bottom: 1px solid rgb(245, 245, 245) !important;
    /*margin: 0 2% !important;*/
}

.rdt_Table {

    background: #fafafa;
    color: #444444;
}

.rdt_TableRow {
    /*background: #444444;*/
    /*color: #0070f3;*/
}

.rdt_TableCol {
    /*background: #A058A2;*/
    color: #ffffff;
}

.rdt_TableCol_Sortable {
    /*background: #444444;*/
    /*color: #0070f3;*/
}

.rdt_TableCell {


}

.rdt_TableRow:hover {
    /*background: #0070f3;*/
    /*color: #444444;*/
}

.rdt_TableHeadRow {
    background: #0C3144 !important;
    border-bottom: 2px solid rgba(12, 49, 68, 0.78) !important;
    /*color: #0070f3;*/
}

.rdt_TableBody {
    /*background: #fafafa;*/
    /*color: #444444;*/
}

.rdt_ExpanderRow {
    /*background: #fafafa;*/
    /*color: #444444;*/
}
