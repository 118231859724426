.btn-register-now{
    background-color: #EA1F24;
    margin: 5px 0;
    font-size: 1.5rem;
    border: none;
    WIDTH: 100%;
    HEIGHT: AUTO;
    /*MIN-HEIGHT: 120PX;*/
    color : white;
    padding: 10px;
}
.btn-register-now:hover{
    background-color: rgba(235, 31, 36, 0.84);
    border-color: rgba(235, 31, 36, 0);
}

.event-list .icon_div {
    display: inline-block;
    margin-left: 0;
    width: 120px;
    vertical-align: middle;
}
.event-list .text_div {
    display: inline-block;
    margin-left: 0;
    width: calc(100% - 150px);
    vertical-align: middle;
}