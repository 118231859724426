.App {
  text-align: center;
  background: #f5f5f5 !important;
}

/*#root{*/
/*  height: 100vh;*/
/*}*/

/*#main-container{*/
/*  height: 100vh;*/
/*}*/

.custom-container{
  background: #f5f5f5;
  border-left: 3px solid #0C3144;
  border-right: 3px solid #0C3144;
  /*height: inherit;*/
  /*padding-top: 5px;*/

}

.panel-margin  > * {
  margin-bottom: 15px;
}

.to-uppercase{
  text-transform: uppercase;
}


.rs-dropdown-item a{
  color: inherit !important;
}

.rs-dropdown-item a:hover{
  color: inherit !important;
  text-decoration: none !important;
  text-decoration-color: inherit !important ;
}



a{
  color: inherit !important;
  text-decoration: none !important;
}

a:hover{
  color: inherit !important;
  text-decoration: none !important;
  /*text-decoration-color: inherit !important ;*/
}
.link-default a{
  color: inherit !important;
}

.link-default a:hover{
  color: inherit !important;
  text-decoration: none !important;
  text-decoration-color: inherit !important ;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #EA1F24 !important;
}
.rs-picker-toggle:focus-visible {
  outline: 3px solid rgba(234, 31, 36, 0.25) !important;
}
.rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle {
  background-color: #fff!important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  /*background-color: #0C3144;*/
}


.rs-loader-spin:after {
  -webkit-animation: s .6s linear infinite;
  animation: s .6s linear infinite;
  border: 3px solid TRANSPARENT;
  border-top: 3px solid #EA1F24 !important;
}
.rs-grid-container-fluid {
   margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.company-header-nav .rs-nav-item {
  font-size: 1rem ;
  white-space: pre-wrap;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #EA1F24 !important;
}