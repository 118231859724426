/*.rs-avatar-lg>.rs-avatar-image {
    width: 100px;
    height: 100px;
}

.rs-avatar-lg {
    width: 100px;
    height: 100px;
}*/

/*
.company-header-wrapper .rs-panel-body{
    display: flex;
}

.company-header-wrapper .rs-panel-body{
    display: inline-block;
}
*/

.rs-divider {
     background-color: inherit;
    /* background-color: var(--rs-divider-border); */
}

.stack-last-item-right .rs-stack-item:last-child{
    margin-left: auto;
}

.university-list .rs-stack-item:last-child{
    margin-left: auto;
}

.company-header-nav .rs-nav-item {
    margin-bottom: 10px !important;
    border-radius: 0;
    background-color: white;
    padding: 20px;
    font-size: 1.5rem;
}

.company-header-nav .rs-nav-item:hover {
    color: white !important;
    background-color: #EA1F24;
}

.company-header-nav .rs-nav-item:focus {
    color: white !important;
    background-color: #EA1F24;
}

.company-header-nav .rs-nav-item-active {
    color: white !important;
    background-color: #EA1F24;
}

.add-new-btn{
    padding: 15px 40px;
}
.rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
}


.company-list-wrapper .rs-panel-body, .rs-panel-header {
    padding:10px 20px;
}

.company-list-wrapper .text-start .icon_div {
    margin-left: 0;
}
.company-list-wrapper .rs-list-item-lg {
    padding-top: 10px;
    padding-bottom: 10px;
}

.company-list-wrapper .rs-badge-independent.rs-badge-dot, .rs-badge-wrapper .rs-badge-content:empty {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 0;
}
.header-flex-col .company-name {
    display: flex;
    flex: 1;
    justify-content: start;
    align-items: baseline;
}
.header-flex-col .company-name:last-child {
    background-color: #333333;
}

.header-flex-col .company-name >* {
   margin-right: 10px;
}

@media only screen and (max-width: 725px) {

    .company-header-nav .rs-nav-item {
        font-size: .75rem ;
        white-space: pre-wrap;
        padding: 5px;
    }

    .add-new-btn{
        padding: 8px 12px;
        white-space: pre-wrap;
    }


}