.blog-img-cover{
    /*aspect-ratio: 16 / 9;*/
    text-align: center;
}
.blog-img-cover img{
    margin: 0 auto;
    max-height: 500px;
    width:100%;
    /*height:100%;*/
    object-fit:cover ;

}
.blog-img-cover img:hover{
    cursor: pointer;
}

.all-blog-img-cover img{
    margin: 0 auto;
    max-height: 500px;
    width:100%;
    /*height:100%;*/
    object-fit:cover ;
}

/*.blogs-card-container{*/
/*!*height: 400px;*!*/
/*}*/
/*.blogs-card-container .rs-panel-header{*/
/*    padding-left: 0;*/
/*}*/

.blogsfeed-row li{
    list-style: none;
    display: inline-block !important;
    vertical-align: top !important;
    width: 48%;
}

.category-button{
    width: 100%;
    margin: 0 auto 10px;
    border-radius: 5px;
    text-align: start;
    background: #ffffff;
    color: #0C3144;
    white-space: break-spaces;
}
@media only screen and (max-width: 1080px) {
    .blogsfeed-row li{
        width: 100%;
    }
}