
.handler-nav .rs-nav-item {
    margin-bottom: 10px !important;
    border-radius: 0;
    background-color: white;
    padding: 15px;
    font-size: 1rem;
}

.handler-nav .rs-nav-item:hover {
    color: white !important;
    background-color: #EA1F24;
}

.handler-nav .rs-nav-item:focus {
    color: white !important;
    background-color: #EA1F24;
}

.handler-nav .rs-nav-item-active {
    color: white !important;
    background-color: #EA1F24;
}