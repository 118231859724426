.news-img-cover{
    text-align: center;
}
.news-img-cover img{
    margin: 0 auto;
    width:100%;
    object-fit:cover ;

}
.news-img-cover img:hover{
    cursor: pointer;
}

.news-avatar-img-cover{
    text-align: center;
}
.news-avatar-img-cover img{
    margin: 0 auto;
    width:auto;
    max-width: 100%;
    max-height: 110px;
    height: auto;
    object-fit:cover ;

}
.news-avatar-img-cover img:hover{
    cursor: pointer;
}

