.login-page .rs-panel {
    background: #fff;
    margin-top: 70px;
    margin-bottom: 70px;
}

.login-page .rs-btn-toolbar {
    line-height: 0;
    margin-right: 25px;
}

.login-page .login-btn {
    color: rgb(255, 255, 255);
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    /*padding: 10px;*/
}

.login-page .login-btn:hover {
background-color: rgba(234, 31, 36, 0.91);
    text-decoration: underline !important;
}
.login-page .login-btn:focus {
background-color: rgb(234, 31, 36);
}

.login-page .rs-btn-link {
    color: #EA1F24 !important;
}
.login-page .new-to-eduinfo {
    color: #333 !important; ;
}

/*.login-page .rs-content {*/
/*    !*height: calc(100%- )*!*/
/*    !* margin: 20px; *!*/
/*    !* padding: 20px; *!*/
/*}*/

@media only screen and (max-width: 996px) {
    .rs-flex-box-grid-item-12 {
        position: relative;
        display: block;
        min-height: 1px;
        width: 90%;
    }
}

