 .register-h2 {
    margin: 20px 0;
    padding: 25px;
    color: white;
}

 .register-row .rs-row{
     margin-bottom: 10px;
 }

.register-nav {
    margin-top: 25px;
}

.register-nav .rs-nav-item {
    margin-bottom: 10px !important;
    border-radius: 0;
    background-color: white;
    padding: 20px;
    font-size: 1.5rem;
}

.register-nav .rs-nav-item:hover {
    color: white !important;
    background-color: #EA1F24;
}

.register-nav .rs-nav-item:focus {
    color: white !important;
    background-color: #EA1F24;
}

.register-nav .rs-nav-item-active {
    color: white !important;
    background-color: #EA1F24;
}
