.create-item-form, .user-profile-edit-form, .Change-password-form .rs-row {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 10px;
}

.create-item-form, .user-profile-edit-form, .Change-password-form .rs-col {
    margin-bottom: 10px;
}

.create-item-form .rs-picker-default .rs-picker-toggle {
    border-radius: 0;
}

.create-item-form .rs-radio-group-picker {
    border-radius: 0;
}

.create-item-form .rs-row {
    margin-bottom: 10px;
}

.create-item-form .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: 200px;
}