/*.footer .copyright {
    padding-top: 15px;
}*/

.footer {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #0C3144 !important;
    color: white;
    /*text-align: center;*/

}

.footer .d-w-r-btn {
    color: #ffffff;
    border-radius: 0;
    font-size: 1.25rem;
    width: auto;
    margin: 0 auto;
    background-color: transparent;
}

.footer .d-w-r-btn:hover {
    background-color: rgb(16, 67, 94);
    color: rgb(255, 255, 255);

    /*
    text-decoration: underline !important;
    */
}
.footer .d-w-r-btn:focus {
    background-color: rgb(16, 67, 94);
    color: rgb(255, 255, 255);
}
.footer .footer-list li {
    width: 33%;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: table;
}

.footer ul li:before, .footer ol li:before {
    content: '';
    width: 4px;
    height: 4px;
    background: #fafafa;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
    position: static;
}