.wrapper {
    background: #f5f5f5;
    top: 50px;
    /* height: 80vh; */
    /*padding-left: 15%;*/
    /*padding-right: 15%;*/
    /*border-left: 3px solid #0C3144;*/
    /*border-right: 3px solid #0C3144;*/
    /*padding-top: 5px;*/
    margin: 0 auto;
    width: 70%;
    overflow: hidden;
}

.heading{
    text-align:center;
    text-transform: uppercase;

}

h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

.rs-header {
    text-align: center;
}

.rs-carousel img{
    background-color: #0C3144;
    object-fit: contain;
}

.default-color {
    color: #EA1F24
}

.default-bgcolor {
    background-color: #EA1F24
}

.border-default {
    border: 1px solid #0C3144;
}

.display-list {
    height: 530px;
    overflow-y: scroll;
    text-align: left;
}

.display-list .rs-btn:hover {
    background-color: #ea1f22b8;
    color: #ffffff;

}

.round-btn{
    border-radius: 20px;
}
.display-list .rs-list-item {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items:center;
}



.top-universities {
    padding: 30px 21% 30px 21%;
    background-color: #fff;
    margin: 0 -21%;
}
.jobs-feed {
    padding: 30px 21% 30px 21%;
    background-color: transparent;
    margin: 0 -21%;
    overflow: hidden;
    border: none !important;
    outline: none !important;
}

.top-universities .heading {
    padding: 0 0 20px 0;
}
.jobs-feed .heading {
    padding: 0 0 20px 0;
}

.top-schools {
    padding: 30px 0 30px 0;
}

.top-schools .heading {
    padding: 0 0 20px 0;
}

.categories-panel>.rs-panel-body{
    padding: 0;
}
.categories-panel a>.rs-panel-body {
    padding: 10px 20px 5px;
}
.categories-panel   {
    border-radius: 0 !important;
}
.categories-panel svg {
    height:70px;
    width:70px;
}
.categories-panel svg image {
    height:70px;
    width:70px;
}
.categories .rs-flex-box-grid-item {
    margin: 5px;
}
.categories .rs-flex-box-grid-item:hover {
    border: 1px solid #EA1F24;
    border-radius: 0;
    box-shadow: 0 0 10px #EA1F24;
}


.text-start {
    text-align: left !important;
}

.text-start .text_div {
    display: inline-block;
    /* margin-left: 10px;*/
    width: calc(100% - 60px);
    vertical-align: middle;
}

.text-start .icon_div {
    display: inline-block;
    /*margin-left: 15px;*/
    width: 30px;
    vertical-align: middle;
}

.text-start .icon_div svg {
    color: red;
    margin-right: 5px;
}


.py-50 {
    padding: 50px 0;
    background-color: transparent;
}

/* width */
.custom-scroll::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 2px #ceb2b2;*/
    /*border-radius: 5px;*/
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #EA1F24;
    /*border-radius: 5px;*/
}

.rs-input {
    border-radius: 0;
}

.rs-input:hover {
    border-color: #EA1F24 !important;
}
.rs-input:focus-visible{
    border-color: #EA1F24 !important;
}
.rs-input:focus {
    box-shadow: 0 0 0 3px rgba(234, 31, 36, 0.26);
    border-color: #EA1F24 !important;
}

.rs-input-group:hover {
    border-color: #EA1F24 !important;
}
.rs-input-group:focus-visible{
    border-color: #EA1F24 !important;
}
.rs-input-group:focus {
    box-shadow: 0 0 0 3px rgba(234, 31, 36, 0.26);
    border-color: #EA1F24 !important;
}
.rs-input-group-focus {
    box-shadow: 0 0 0 3px rgba(234, 31, 36, 0.26);
    border-color: #EA1F24 !important;
}

.rs-input-group{
    border-radius: 0;
}
.rs-tooltip{
    background-color: #EA1F24;
}
.rs-tooltip[class*=placement-top]:after {
    border-top-color: #EA1F24;
}


.d-w-r-btn {
    color: rgb(255, 255, 255) !important;
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    background-color: #EA1F24;
}

.d-w-r-btn:hover {
    background-color: rgba(234, 31, 36, 0.91);
    color: rgb(255, 255, 255) !important;

    /*
    text-decoration: underline !important;
    */
}
.d-w-r-btn:focus {
    background-color: rgb(234, 31, 36);
    color: rgb(255, 255, 255) !important;
}
.d-w-r-btn:disabled {
    background-color: rgb(236, 102, 104);
    color: rgb(255, 255, 255) !important;
}
.d-r-w-btn {
    color: #EA1F24;
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    background-color: #f5f5f5;
}

.d-r-w-btn:hover {
    background-color: #f5f5f5;
    color: #EA1F24;


    /*
    text-decoration: underline !important;
    */
}

.welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    background: white;
    /*width: 100%;*/
}
.welcome p{
    font-size: 40px;
    font-weight: bold;
    color: #EA1F24;
    margin-bottom: 0;
}

.d-r-w-btn:focus {
    background-color: #f5f5f5;
    color: #EA1F24;
}
.show-grid {
    display: flex;
}

.image-cover{
    /*aspect-ratio: 16 / 9;*/
    text-align: center;

}
.image-cover img{
    margin: 0 auto;
    width:100%;
    height:100%;
    object-fit:cover ;

}

.events-pic{
    width: 100%;
    height: 600px ; ;
}

.flex-end-space-bw{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.container-customer-for-dots{
 color: #EA1F24;
    background: white;
}
@media only screen and (max-width: 1080px) {

    .flex-custom-reverse {
        flex-direction: column-reverse !important;
    }

    .wrapper {
       /* padding-left: 1%;
        padding-right: 1%;*/
        width: 98%;
    }
    /*.events-pic{*/
    /*    width: 100%;*/
    /*    height: 50% !important;*/
    /*}*/

    .rs-carousel {
        position: relative;
        height: auto !important;
        overflow: hidden;
    }

    .top-universities {
        padding: 30px 0 30px 0;
        background-color: #fff;
        margin: 0 0;
    }

    .header-flex-col {
        align-items: center;
        gap: 20px;
        flex-direction: column;
    }
    .header-flex-col .rs-stack-item:last-child{
        margin: auto;
    }
    .show-grid {
        display: block;
    }
    .display-list {
        max-height: 530px;
        height: auto;
    }
}
@media only screen and (max-width: 725px) {

    .company-header-nav .rs-nav-item {
        font-size: .75rem ;
        white-space: pre-wrap;
        padding: 0;
    }
    .events-pic{
        width: 100%;
        height: 300px ;
    }
    .events-pic img{
        margin: 0 auto;
        width:100%;
        height:100%;
        object-fit:cover ;
    }


}


.temp .rs-col{

    display: flex;
    justify-content: center;

}