
.search-box {
    padding: 20px 30px;
    margin:0 auto;
    font-size: 19px;
}

 .wrapper-search {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);

}
 .wrapper-search .rs-grid-container-fluid {
    padding-left: 0;
    padding-right: 0;
}



 .wrapper-search .rs-col {
    padding-left: 0;
    padding-right: 0;
}

 .wrapper-search .rs-picker-toggle-wrapper {
    display: block;
 
}


 .wrapper-search input {
    border-radius: 0;

}

 .wrapper-search .rs-picker-default .rs-picker-toggle {
     border-radius: 0;
}


/* .search-box input #search-bar { */
    /* width: 50%; */
    /*border-radius: 0;*/
    /*padding: 20px 20px;*/
/* } */

/* .search-box .rs-picker-default .rs-picker-toggle.rs-btn {
    padding: 20px 29px 20px 15px;
}

.search-box .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
    text-overflow: ellipsis;
}

.search-box .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 20px;
}

.search-box .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    right: 30px;
}

.search-box .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 20px !important;
}

.search-box .search-box .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    right: 28px !important;
}

.search-box .rs-btn-ghost.rs-btn {
    padding: 20px 20px;
    background-color: #EA1F24;
    color: #fafafa;
    border-color: #e5e5ea;
    padding: 20px 29px 20px 15px;
    width: 25%

}

.search-box .rs-btn-ghost.rs-btn:hover {
    background-color: rgba(234, 31, 36, 0.84);
}
 */
