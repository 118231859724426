.article {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
}

.header {
    font-size: 80px;
    color: white;
    text-align: center;
}
.newsfeed-header{
    position: relative;
}
.newsfeed-container:hover{
    /*box-shadow: 0 0 0 3px rgba(234, 31, 36, 0.26);*/
    transform:scale(1.1);
}

.hover-news-feed{
    position: absolute;
    background: rgba(12, 49, 68, 0.8);
    bottom: 7%;
    width: 90%;
    padding-left: 10px;
    margin-bottom: 0;
    font-size: 13px;
    /*font-size-adjust: 0.5;*/

}