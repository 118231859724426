.header-wrapper {
    border-left: 3px solid #0C3144;
    border-right: 3px solid #0C3144;

    background: #0C3144;


}

.header-wrapper .navbar-wrapper {
    background: #fff;
    padding: 30px 15% 30px 15%;


}

.topbar {
    padding: 5px 15%;
    background: #0C3144;
    /*height: 40px;*/
}

.topbar a {
    color: #fafafa !important;
    /*margin: 10px auto;*/
    /*padding: 10px;*/
}

.rs-nav-horizontal {
    white-space: normal;
}

.border-bottom {
    border-bottom: 2px solid #0C3144;
    padding-bottom: 5px;
}
.nav-header .rs-navbar-item {
    /*height: 20px;*/
    /*margin-bottom: 10px !important;*/
    /*border-radius: 0;*/
    /*background-color: white;*/
    /*padding: 20px;*/
    /*font-size: 1.5rem;*/
    padding: 9px 10px;
    margin: 0 2px !important;
}

.nav-header .rs-navbar-item:hover {
    /*color: white !important;*/
    /*padding-bottom: 20px;*/
    /*background-color: #EA1F24;*/
    /*background-color: #EA1F2411;*/
    border-bottom: 1px solid #EA1F24;
    margin: 0 2px;


}

.nav-header .rs-navbar-item:focus {
    /*color: white !important;*/
    /*padding-bottom: 20px;*/
    border-bottom: 1px solid #EA1F24;

    /*background-color: #EA1F24;*/
}

.nav-header .rs-navbar-item-active {
    color: inherit !important;
    border-bottom: 1px solid #EA1F24;
    /*background-color: #EA1F24;*/
}


@media only screen and (max-width: 996px) {
    .header-wrapper .navbar-wrapper {
        /*background: #fff;*/
        padding: 30px 1% 30px 1%;


    }
    .display-none-sm{
        display: none;
    }
    .topbar {
        padding: 5px 1%;
/*        padding-top: 5px;
        background: #0C3144;
        height: 40px;*/
    }

}